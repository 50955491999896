body,
html {
  overflow-x: hidden;
  background-color: $black;
}

a {
  text-decoration: none;
  color: $white;
}

h1,
h2,
h3 {
  line-height: 1.3;
  font-weight: 900;
}

.small,
small {
  font-size: 0.7rem;
  @include media-breakpoint-down(md) {
    font-size: 0.7rem;
  }
}

.xsmall {
  font-size: 0.5rem;
}

.lead {
  font-weight: 500;
  @include media-breakpoint-down(md) {
    font-size: 0.9rem;
  }
}

h1 {
  @include media-breakpoint-down(md) {
    font-size: 1.375rem;
  }
}
h2 {
  letter-spacing: 1.44px;
  @include media-breakpoint-down(md) {
    font-size: 1.125rem;
  }
}

h3 {
  letter-spacing: 1.44px;
  @include media-breakpoint-down(md) {
    font-size: 1rem;
  }
}

p {
  color: $white;
  font-weight: 300;
  @include media-breakpoint-down(md) {
    font-size: 0.8rem;
  }

  &.nunito {
    font-family: $headings-font-family;
    font-weight: 700;
  }
}

.fw-bold {
  font-weight: 700;
}

.btn {
  letter-spacing: 1.4px;

  img {
    margin-left: 10px;
  }

  &.gradient-orange {
    background: $orange-gradient;
  }
}

.form {
  .error {
    .form-control {
      box-shadow: 2px 3px 2px rgba($red, 0.2);
      background-color: rgba($red, 0.2);
    }
    .form-check-input[type="radio"] {
      background-color: rgba($red, 0.2);
    }

    label p {
      color: $red;
    }
  }
}

.form-check {
  a {
    text-decoration: underline;
    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
}

.form-control {
  font-size: 0.8rem;
  box-shadow: 2px 3px 2px rgba(13, 50, 85, 0.2);
  border-radius: 28px;

  &:focus {
    box-shadow: 2px 3px 2px rgba(13, 50, 85, 0.1);
  }
}

.narrow {
  max-width: 1000px;
}

.narrower {
  max-width: 780px;
}

.form-check-input {
  background-color: transparent;
  border: 2px solid $white;
  width: 20px;
  height: 20px;
}

.form-check-input:checked {
  background-color: $white;
}

.form-check-input:focus {
  border-color: $white;
  outline: 0;
  box-shadow: none;
}

.radio-image,
.radio-text {
  p {
    font-family: $headings-font-family;
    font-size: 0.8rem;
    font-weight: 900;
  }
}

.radio-image {
  input[type="radio"] {
    display: none;
  }

  label {
    //border: 3px solid #fff;
    //width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    //border-radius: 50%;
    //background-color: #d3ccf9;
    //padding: 15px;
    display: block;
    position: relative;
    margin: 10px auto;
    cursor: pointer;

    &:hover {
      //background-color: rgba(#9082e1, 0.6);
    }

    @include media-breakpoint-down(md) {
      padding: 10px;
      //margin: 0 0 10px 0;
    }
  }

  label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }

  label img {
    height: 100px;
    width: 100px;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
    transform: scale(1);
    margin-bottom: 10px;
  }

  :checked + label {
    //background-color: #9082e1;
  }

  /*   :checked + label:before {
    content: "✓";
    background-color: grey;
    transform: scale(1);
  } */

  :checked + label img {
    transform: scale(1.1) translateY(-10px);
    //filter: drop-shadow(2px 3px 2px rgba(209, 94, 20, 0.5));
    //box-shadow: 0 0 5px #333;
    z-index: -1;
  }
  :checked + label p {
    color: $orange-dark;
  }
}

.radio-text {
  input[type="radio"] {
    display: none;
  }

  label {
    //border: 3px solid #fff;
    //width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: block;
    position: relative;
    margin: 5px auto;
    padding: 15px;
    cursor: pointer;

    background: #ffffff;
    box-shadow: 2px 3px 2px rgba(13, 50, 85, 0.2);
    border-radius: 18px 32px;

    &:hover {
      background: rgba(209, 94, 20, 0.04);
      box-shadow: 2px 3px 2px rgba(209, 94, 20, 0.5);
    }

    @include media-breakpoint-down(md) {
      padding: 10px 15px;
      margin: 10px auto;
    }

    p {
      margin-bottom: 0;
    }
  }

  label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    //border: 1px solid grey;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }

  :checked + label {
    background: rgba(209, 94, 20, 0.04);
    box-shadow: 2px 3px 2px rgba(209, 94, 20, 0.5);
  }

  /* :checked + label:before {
    content: "✓";
    background-color: grey;
    transform: scale(1);
  } */

  :checked + label p {
    transform: scale(1);
    //box-shadow: 0 0 5px #333;
    z-index: -1;
    color: $orange-dark;
  }
}

.message {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: $black;
  .sent {
    width: 100px;
  }
}

.filled-icons {
  color: #00c9b7 !important;
}

.btn-outline-pink-dark {
  &:hover {
    background-color: rgba($pink-dark, 0.2);
  }
}

.border-box {
  img {
    width: 60px;
  }

  @include media-breakpoint-up(md) {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.backdrop {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  text-align: center;
  background: rgba(9, 23, 34, 0.95);
  backdrop-filter: blur(2px);

  .bottom-racek {
    position: absolute;
    bottom: -30px;
    right: 0;
    width: 420px;
    animation: float 6s ease-in-out infinite;
    @include media-breakpoint-down(md) {
      width: 200px;
    }

    &__hide {
      opacity: 0;
      animation: float-quick 2s ease-out;
    }
  }

  .bottom-text {
    position: absolute;
    bottom: 20px;
    width: 50%;
  }
}

.rws-backdrop {
  justify-content: center !important;
}

.rws-close {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.rws-container {
  background-color: $blue !important;
  /*   position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.rws-header {
  color: white;
  text-align: center;
}
.rws-icon {
  background-color: transparent !important;
}

.rws-icon[aria-label="whatsapp"],
.rws-icon[aria-label="reddit"],
.rws-icon[aria-label="telegram"],
.rws-icon[aria-label="mail"],
.rws-icon[aria-label="vk"] {
  display: none;
}

///////////////
// HOME
///////////////

// NAV
.nav-desktop {
  .icon {
    width: 45px;
  }
}

// USPS

.usps {
  img {
    max-width: 90px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// Header
.hamburger-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  cursor: pointer;
  padding: 10px;
  z-index: 11;
}

.noscroll {
  overflow: hidden;
  height: 100%;
}

.list-inline-item {
  @include media-breakpoint-down(sm) {
    display: block;
    padding: 15px 0;
  }
}

.cta {
  font-family: $headings-font-family;
  color: $orange;
  text-transform: uppercase;
  position: relative;
  &:hover {
    color: $orange;
  }
  &__arrow {
    &:after {
      transition: all 0.2s ease-in-out;
      content: "";
      position: absolute;
      right: -20px;
      width: 100%;
      height: 100%;
      background: url("/assets/images/_lunaai/icon-arrow.svg") no-repeat center
        right;
    }

    &:hover {
      &:after {
        right: -25px;
      }
    }
  }
}

// Testimonials
.testimonials {
  .item {
    &:hover {
      .cta {
        &__arrow {
          &:after {
            right: -25px;
          }
        }
      }
    }
  }
}

.headline {
  max-width: 450px;
  margin: 0 auto;
}

.remember {
  img {
    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 50%;
      height: 600px;
      width: auto;
      transform: translateY(-50%);
      right: -30%;
      z-index: 0;
    }
  }
}

.dogodivscine {
  position: relative;
  z-index: 1;
}

.story {
  div {
    font-size: 0;

    h1 {
      font-family: "dumbledor_3regular";
      font-size: 2.5rem;
      color: $orange;
      margin-bottom: 1.2rem;
      line-height: 1;
    }
    h2 {
      font-size: $h2-font-size;
      color: $orange;
      margin-bottom: 2rem;
      letter-spacing: 1.44px;
    }
    p {
      font-size: 0.95rem;
    }

    button {
      div {
        font-size: 0.7rem;
      }
    }
  }

  .supertitle {
    text-transform: uppercase;
    letter-spacing: 0.84px;
    text-shadow: 0px 2px 8px 0px rgba(255, 255, 255, 0.5);
    font-weight: 500;
    font-size: 14px;
  }
}

.block {
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: $white;
    }
  }
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  backdrop-filter: blur(5px);
  background-color: rgba($black, 0.5);

  .popup-inner {
    border: 2px solid $white;
    padding: 20px;
    border-radius: 20px;
    position: relative;
    @include media-breakpoint-down(md) {
      width: 90%;
      padding: 10px;
    }

    .close {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}

.transform-none {
  text-transform: none;
}

.spinner-border {
  width: 1rem;
  height: 1rem;
}

.close {
  cursor: pointer;
}

.react-simple-star-rating {
  svg {
    margin: 0 3px;
  }
}

.cookie-notice {
  position: fixed;
  z-index: 5;
  width: 100%;
  bottom: 0;
  background: radial-gradient(
    127.03% 148.03% at 100% 109.15%,
    #f37a21 0%,
    #e35d26 100%
  );
  @include media-breakpoint-down(md) {
    bottom: 0;
  }

  .cookie-wrapper {
    padding: 25px;

    @include media-breakpoint-down(md) {
      padding: 10px;
      border-radius: 0;
    }
  }

  label {
    font-size: 0.75rem;
  }

  .form-check-input {
    font-size: 0.75rem;
  }

  .arrow {
    &.rotate {
      transform: rotate(180deg);
    }
  }

  a {
    color: $white;
    font-weight: 700;
  }
}

.subpage {
  .footer {
    svg {
      display: none;
    }
  }
}

.admin {
  table {
    table-layout: fixed;
    width: 100%;
  }
}

@keyframes float {
  0% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    transform: translatey(0px);
  }
  50% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.2));
    transform: translatey(-15px);
  }
  100% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    transform: translatey(0px);
  }
}

@keyframes float-quick {
  0% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    transform: translatey(0px);
    opacity: 1;
  }
  75% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.2));
    transform: translatey(-30px);
    opacity: 1;
  }
  100% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    transform: translatey(450px);
    opacity: 1;
  }
}

///////
/// SFF
/// ///
///
///

.section-hero {
  position: relative;
  width: 100%;
  position: relative;
  background: url("/assets/images/_sff/bg-hero.jpg"), no-repeat center top;
  background-repeat: no-repeat;
  background-size: 100% auto;
  min-height: 100vh;
  background-blend-mode: multiply;

  @include media-breakpoint-down(md) {
    background: url("/assets/images/_sff/bg-hero-mobile.png"),
      linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      no-repeat center top;
    background-repeat: no-repeat;
  }

  &-overlay {
    background: url("/assets/images/_sff/bg-hero.jpg"),
      linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
      no-repeat center top;
  }
  .bg-hero {
    width: 100%;
    position: relative;
    background: url("/assets/images/_sff/bg-hero.jpg"), no-repeat center top;
    background-size: 100% auto;
  }

  .title-animation {
    padding-top: 15%;
    @include media-breakpoint-down(md) {
      padding-top: 55%;
    }
    .title-animation-title {
      /*     position: absolute;
      left: 50%;
      top: 21%;
      transform: translateX(-50%);
  
      @include media-breakpoint-down(md) {
        width: 100%;
        top: 24%;
      } */
    }

    .title-animation-logos {
      text-align: center;
      height: 50px;
      /*     position: absolute;
      left: 50%;
      top: 25%;
      transform: translateX(-50%);
      @include media-breakpoint-down(md) {
        top: 28%;
        width: 80%;
  
        img {
          width: 100%;
        }
      } */
    }
  }

  .description {
    padding-top: 10.5%;
    @include media-breakpoint-down(md) {
      padding-top: 27%;
    }
    /*     text-align: center;
    position: absolute;
    left: 50%;
    top: 41.5%;
    transform: translateX(-50%);

    @include media-breakpoint-down(md) {
      top: 49%;
      width: 100%;
      padding: 0 20px;
    } */
  }

  .splash {
    padding-top: 80px;
    text-align: center;
    .loader {
      width: 50px;
      height: 50px;
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(253deg)
        brightness(106%) contrast(102%);
    }
  }

  .header {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2;

    .logo-argeta {
      @include media-breakpoint-down(md) {
        width: 177px;
      }
    }

    .logo-sff {
      @include media-breakpoint-down(md) {
        width: 80px;
      }
    }
  }
}

.image-slider {
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  position: relative;
  background: url("/assets/images/_sff/movie-tape.png") repeat-x center top;
  background-size: auto 100px;
  height: 100px;
  @include media-breakpoint-down(md) {
    height: 80px;
    background-size: auto 80px;
  }
  img {
    margin: 0 5px;
    height: 60px;
    @include media-breakpoint-down(md) {
      margin: 0 3px;
      height: 50px;
    }
  }
}

/* .image-slider {
  .wrapper {
    width: 100%;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
  }

  .slider {
    display: flex;
    flex-wrap: nowrap;
    scroll-snap-align: start;
  }

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
} */

// Badge top5
.badge-top5 {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  background-color: $orange;
  position: fixed;
  bottom: 10px;
  right: 10px;

  small {
    text-align: center;
    line-height: 110%;
    margin-top: 5px;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.6px;
    text-transform: uppercase;
  }
}

// Top5
.section-stats {
  .items {
    h2.title {
      font-family: "Ubuntu";
      font-size: 1.75rem;
      font-weight: 300;
      @include media-breakpoint-down(md) {
        font-size: 1.4rem;
      }
    }
    /*     > div:nth-child(1) {
      h2.title {
        font-family: "Action Hero";
      }
    }

    > div:nth-child(2) {
      h2.title {
        font-family: "gummy_bearregular";
      }
    }

    > div:nth-child(3) {
      h2.title {
        font-family: "theater_brillion_regular";
      }
    }

    > div:nth-child(4) {
      h2.title {
        font-family: "dumbledor_3regular";
      }
    }

    > div:nth-child(5) {
      h2.title {
        font-family: "haunting_attractionregular";
      }
    } */
  }
}

// Quiz

.quiz {
  padding-top: 8%;
  @include media-breakpoint-down(md) {
    padding-top: 14%;
  }

  .text-answer-wrapper {
    button {
      width: 100%;
      letter-spacing: 0;
      font-size: 1rem;
    }
  }

  .image-answer-wrapper {
    button {
      width: 100%;
      letter-spacing: 0;
      font-size: 1rem;
      p {
        height: 80px;
      }
      img {
        aspect-ratio: 1 / 1;
      }
    }
  }

  .btn-orange {
    &:hover,
    &:focus,
    &:active {
      border: 1px solid white;
      border-color: $white;
    }
  }
}

// Progress bar
.step-progress-bar {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  background: transparent;
  border: 1px solid $white;
  border-radius: 10px;

  .stepBullet {
    background: transparent;
    height: 12.65px;
    list-style: none;
    padding-left: 0;
    z-index: 2;

    &.done {
      background: $white;
      border-radius: 10px;
      filter: drop-shadow(0px 1px 8px rgba(255, 255, 255, 0.7));
      transition: width 0.5s ease-in-out;
    }

    &.active {
      background: transparent;
    }
  }
}

// Result
.result {
  padding-top: 12%;
  @include media-breakpoint-down(md) {
    padding-top: 27%;
  }
}

// Static
.static {
  padding-top: 5%;
  @include media-breakpoint-down(md) {
    padding-top: 27%;
  }

  a {
    color: $white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

//Read more section
.readmore-section {
  p {
    font-size: 11px;
  }

  a {
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: $white;
    }
  }
}

.result-generated {
  h1 {
    display: none;
  }
}

// Footer

.footer {
  background: url("/assets/images/_sff/tape.svg") repeat-x center 4px;
  //background-size: auto 20px;

  li {
    font-size: 0.75rem;
  }
}
