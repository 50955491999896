/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 13, 2023 */

@font-face {
  font-family: "theater_brillion_regular";
  src: url("theater_brillion-webfont.woff2") format("woff2"),
    url("theater_brillion-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "dumbledor_3regular";
  src: url("dum3-webfont.woff2") format("woff2"),
    url("dum3-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "gummy_bearregular";
  src: url("gummybear-webfont.woff2") format("woff2"),
    url("gummybear-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "haunting_attractionregular";
  src: url("haunting_attraction-webfont.woff2") format("woff2"),
    url("haunting_attraction-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Action Hero";
  src: url("ActionHeroRegular.woff2") format("woff2"),
    url("ActionHeroRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
